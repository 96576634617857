<template>
  <div class="container mt-5">
    <div class="card shadow">
      <div class="card-header">
        <h6>Redirect</h6>
      </div>
      <div class="card-body text-center">
        You are being redirected, please wait.
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import { request } from "@/common/mixins/mix_helper";
import { loader } from "@/common/mixins/mix_loader";
import { staffCookies } from "@/common/mixins/mix_cookies";
import AppConfig from "@/common/config/app.config.json";
import { util } from "@/common/mixins/mix_helper";

export default {
  name: "AuthMonitoring",
  mixins: [request, loader, staffCookies, util],
  methods: {
    firebaseAuth: function (token) {
      this.loading();
      let params = this.jwtDecode(token);
      let payload = {
        email: params.email,
        password: params.fbkey,
      };
      this.$store
        .dispatch("FbAuthLogin", payload)
        .then(() => {
          this.authProcess(token);
        })
        .catch(() => {
          this.$router.push("/auth");
        });
    },

    /**
     * --------------------------------------------------------------
     * request API
     * --------------------------------------------------------------
     */
    authProcess: function (token) {
      this.API.post(this.URL.auth.admin, { token: token }, false)
        .then(({ data }) => {
          this.cookies.add(data.data.cookies);
          localStorage.setItem(
            AppConfig.rwStorage.agent.token,
            data.data.token
          );
          this.$router.push("/agent");
        })
        .catch(() => {
          this.$store.dispatch("FbAuthSignOut");
          this.$router.push("/auth");
        })
        .finally(() => {
          this.loading(false);
        });
    },
  },
  mounted() {
    let token = this.$route.query.token;
    if (typeof token !== "undefined") {
      this.firebaseAuth(token);
    } else {
      this.$router.push("/auth");
    }
  },
};
</script>
